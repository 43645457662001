<template>
  <div class="home">
    <a-spin class="load" v-show="loading" />
    <div class="readImg" @click="goPolicy()">
      <div class="readTitle">政策解读</div>
      <div class="readEnglish">Policy Analyzing</div>
    </div>
    
    <div class="content">
      <HeadTop />
      <ul class="contLeft">
        <li v-if="lfList.length<=0">暂无数据</li>
        <li v-else :class="currentIndex==index?'activeLi':''" v-for="(item,index) in lfList" :key="index" @click="checkname(item,index)">{{item.title}}</li>
      </ul>
      <div class="contRight">
        <div class="empty" v-if="list1.length==0">
          <a-empty style="padding-top:150px"></a-empty>
        </div>
        <ul v-else class="listUl">
            <li class="listLi" v-for="(item,index) in list1" :key="index" @click="goDetail(item)">
                <div class="userDetail">
                    <div class="name">{{item.title}}</div>
                    <div class="content">{{item.content}}</div>
                    <div class="date">
                      <span>{{format.format(item.createDate)}}</span>
                      <span class="dianzan">
                        <template>
                          <span class="like" key="like-o">
                            <a-icon type="like" :theme="item.isPraise === 1 ? 'filled' : 'outlined'"
                                    @click="praise(item,1)" />
                            {{item.praiseNum || 0}}
                          </span>
                          <span key="message" class="goodMess">
                            <a-icon type="message" @click="Infobox(item,1)" />
                            {{item.commentNum}}
                          </span>
                        </template>
                      </span>
                    </div>
                </div>
            </li>
        </ul>
        <div style="text-align:center;margin:15px 0px;">
        <a-config-provider>
          <a-pagination 
            show-quick-jumper
            :default-current="1"
            :page-size="pageSize"
            @change="handleListChange"
            show-less-items
            :total=total />
          </a-config-provider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import format from '../../utils/format.js'
import HeadTop from '@/components/HeadTop.vue'
import axios from 'axios'
export default {
  name: 'examinationRoom',
  data(){
    return {
      format,
      loading: false,
      currentIndex: 0,
      pageSize: 10,
      pageNum:1,
      total: 0,
      lfList:[], //左边分类列表
      classifyId: 0, // 分类ID
      list1:[]
    }
  },
  mounted(){
    this.leftList()
    this.getList()
  },
  methods: {
    leftList(){
      this.loading = true
      let _this = this
      axios({
        url: this.$baseUrl + 'official/examHall/getPolicyClassifyList',
        method: 'POST',
        data: {}
      }).then(res => {
        if(res && res.data &&res.data.resultCode == 0){
          _this.lfList = res.data.resultData
          _this.loading = false
        }
      })
    },
    getList () {
      this.loading = true
      const param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        classifyId: this.classifyId
      }
      let _this = this
        axios({
          url: this.$baseUrl + 'official/examHall/getPolicyPage',
          method: 'POST',
          data: param
        }).then(res => {
          if(res && res.data &&res.data.resultCode == 0){
            _this.list1= res.data.resultData.list
            _this.total = res.data.resultData.total
            _this.loading = false
            if(_this.list1.length==0){
              _this.list1 = []
            }
          }
        })
    },
    checkname (item, index){
      this.currentIndex = index
      this.classifyId =  item.id
      this.getList()
      // this.$router.push({name: item.key})
    },
    handleListChange (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.getList()
    },
    goPolicy () {
      this.$router.push({name: 'policy'})
    },
    goDetail (item){
      // console.log(item)
      sessionStorage.setItem('policyMess',JSON.stringify(item))
      this.$router.push({name: 'policy', params: {item}})
    }
  },
  components: {
    HeadTop
  }
}
</script>
<style scoped lang="less">
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}
  .home{
    background: #f6f6f6;
    margin-bottom: 40px;
    .load{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(50%, 50%);
    }
    .readImg{
      width:100%;
      height:260px;
      background: url('../../assets/img/42.png');
      background-size: 100% 100%;
      position: relative;
      .readTitle{
        text-align: center;
        color: #323232;
        line-height: 55px;
        letter-spacing: 4px;
        font-size: 36px;
        padding-top: 80px;
      }
      .readEnglish{
        color: #3D3D3D;
        line-height: 27px;
        letter-spacing: 2px;
        font-size: 16px;
        text-align: center;
      }
    }
    .content{
      // display: flex;
      width: 1140px;
      margin: auto;
      // margin-bottom: 40px;
      .contLeft{
        width: 200px;
        height: 500px;
        background: #fff;
        padding: 20px 0px;
        float: left;
        li{
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .contRight{
        margin-left: 20px;
        margin-bottom: 20px;
        width: 920px;
        min-height: 500px;
        background: #fff;
        float: left;
        .empty{
          min-height: 430px;
        }
        .listUl{
          padding: 30px;
          min-height: 430px;
            .listLi{
                display: flex;
                border-bottom: 1px solid #eee9de;
                width: 100%;
                padding: 10px 0px;
                cursor: pointer;
                .userDetail{
                    margin-left: 5px;
                    width: 100%;
                    margin-left: 10px;
                    .name{
                      height: 28px;
                      line-height: 28px;
                      color: #000;
                      font-size: 16px;
                      font-weight: 500;
                      font-size:20px;
                    }
                    .content{
                        margin-top: 8px;
                        line-height: 20px;
                        font-size: 16px;
                        width:100%;
                        color: #000;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display:-webkit-box;
                        -webkit-box-orient:vertical;
                        -webkit-line-clamp:2;
                    }
                    .date{
                        margin-top: 15px;
                        color: #7F7F7F;
                        font-size: 12px;
                        .dianzan {
                          float: right;
                          width: 300px;
                          text-align: right;
                          .like{
                            margin-right: 10px;
                          }
                          .goodImg {
                            width: 20px;
                            height: 20px;
                            margin-top: -8px;
                            cursor: pointer;
                          }
                        }
                    }
                }
            }
        }
      }
    }
  }
  .activeLi{
    background: #DB5647;
    color: #fff;
  }
</style>
